/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://6xn7ei35xjbvvpejmjyjyqegni.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-44wergf4krh5xduudmi7lisv5y",
    "aws_cognito_identity_pool_id": "us-west-2:21d9cdf3-30ad-4753-8d49-da6ec7e86275",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_FUVC3VcHA",
    "aws_user_pools_web_client_id": "70s2bgi39r10u56c70afht61ar",
    "oauth": {}
};


export default awsmobile;
